import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

const Modal = () => externalComponent('modal')
const FormInput = () => externalComponent('formInput')
const FormSelect = () => externalComponent('formSelect')

export default {
  name: 'ModalCreateService',

  components: {
    Modal,
    FormInput,
    FormSelect
  },

  props: ['mode'],

  data() {
    return {
      service: {
        icon: '',
        title: '',
        description: '',
        price: '',
        type: ''

      },

      formSelect: {
        name: "Select whether it is a single or multiple service.",
        placeholder: "Select type of service ...",
        options: [
          {
            name: "Single",
            value: "single"
          },
          {
            name: "Multiple",
            value: "multiple"
          }
        ]
      }
    }
  },

  methods: {
    close: function() {
      EventBus.$emit('hideModalCreateService');
    },

    saveService: async function() {
      // this.service.price = this.service.price*100;
      
      let data = this.service,
        response;

      if(
        data.icon == '' ||
        data.title == '' ||
        data.description == '' ||
        data.price == '' ||
        data.type == ''
      ) {
        this.$toast.error('Warning: Complete the entire form.');
        return;
      }

      await axios
      .post(apiDomain + "/service", data)
      .then(res => response = res)
      .catch(err => response = err);
      
      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Service success was saved.');
        this.close();
      } else {
        this.$toast.error('Failed to save service.');
      }
    }
  }
}
